import { createStore } from 'redux'

const initialState = {
  inputFields: []
};

const reducer = (state = initialState, action) => {
  if (action.type === 'FAQS_DATA') {
    return Object.assign({}, state, {
      inputFields: state.inputFields.concat(action.payload)
    })
  }

  return state

};

const store = createStore(reducer);

export default store
