import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/components/Pages/Login'));
const TwoFA = React.lazy(() => import('./views/components/Pages/2FA'));
const Register = React.lazy(() => import('./views/components/Pages/Register'));

const SetPassword = React.lazy(() => import('./views/components/Pages/SetPassword'));
const ForgotPassword = React.lazy(() => import('./views/components/Pages/ForgotPassword'));
const Page404 = React.lazy(() => import('./views/components/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/components/Pages/Page500'));
const Call = React.lazy(() => import('./views/supportomate/Call'));
const AllCalls = React.lazy(()=> import('./views/supportomate/SessionsTable'));
const Subscribed = React.lazy(()=> import('./views/components/Pages/Subscribed'));
const CanceledSubscribe = React.lazy(()=> import('./views/components/Pages/CanceledSubscribe'));
const CustomizedDashboard = React.lazy(()=> import('./views/dashboards/custom'));


class App extends Component {
  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/subscribed" name="Subscribed" render={props => <Subscribed {...props}/>} />
              <Route exact path="/canceled" name="Canceled Subscribe" render={props => <CanceledSubscribe {...props}/>} />
              <Route exact path="/call" name="Call View" render={props => <Call {...props}/>} />
              <Route exact path="/calls" name="Calls View" render={props => <AllCalls {...props} loadMore={true} withFilter={true}/>} />
              <Route exact path="/report/:id" name="Report" render={props => <CustomizedDashboard {...props} loadMore={true}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/2fa" name="Two Factor Auth Page" render={props => <TwoFA {...props}/>} />
              <Route exact path="/register" name="Register" render={props => <Register {...props}/>} />
              <Route exact path="/setPassword/:token" name="SetPassword Page" render={props => <SetPassword {...props}/>} />
              <Route exact path="/forgotPassword" name="ForgotPassword Page" render={props => <ForgotPassword {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => {
                return <DefaultLayout {...props} />;
              }} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}


export default App;
